global.jQuery = require('jquery');

import 'bootstrap';
require('./numeric-solve.min.js');
require('./d3.v3.min.js');
import "../scss/app.scss";
var $ = jQuery.noConflict();
const dropArea = $("#imageDropZone");
const ImageCanvas = $("#imageCanvas");
var SceneLayers = [];
var currentLayer = 0;
var ImageHolder = null;
var SceneHolder = null;
var selectedCorner = null;
var selectedPanel = null;
var defaultPanel = {
    width: 64,
    height: 96
};

function stopEventTriggering(e) {
    e.stopPropagation();
    e.preventDefault();
}

function handleImagDrop (e) {
    stopEventTriggering(e);
    let dt = e.originalEvent.dataTransfer;
    let files = dt.files;
    let count = files.length;
    // just get the first image from dropped ressources
    let found = false;
    for (let i = 0; i < files.length; ++i) {
        if (!found && files[i].type.indexOf("image") !== -1) {
            ImageCanvas.html("");
            found = true;
            let file = files[i];
            document.file = file;
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener('load', pasteDataToPictureContainer);
        }
    }
    $(e.currentTarget).removeClass("drag");
    if (found == false) setImageIsFaulty (true);
    else addToolBar();
}
function handleImageDragEnter (e) {
    e.stopPropagation();
    e.preventDefault();
}

function handleImagDragOver (e) {
    stopEventTriggering(e);
    $(e.currentTarget).addClass("drag");
}
function handleImagDragLeave (e) {
    stopEventTriggering(e);
    $(e.currentTarget).removeClass("drag");
}

function createSvgRoot(target,attributes = {}) {
    let root = appendSVGChild("svg",target,attributes);
    return appendSVGChild("g",root,{
        transform: "translate(0,0)"
    });
}

function appendSVGChild(elementType,target,attributes = {},text = '') {
  const element = document.createElementNS('http://www.w3.org/2000/svg',elementType);
  Object.entries(attributes).map(a => element.setAttribute(a[0],a[1]));
  if (text) {
    const textNode = document.createTextNode(text);
    element.appendChild(textNode);
  }
  target.appendChild(element);
  return element;
};


function handleAddNewShape3d (e) {
    stopEventTriggering(e);
    generateNewSceneLayer();
}

function generateNewSceneLayer(){


    let width = ImageHolder.outerWidth();
    let height = ImageHolder.outerHeight();

    //TODO: make it possibke to draw zhe rectangle my user input
    //FIX: use userinput for calculation
    currentLayer = SceneLayers.length;
    SceneLayers[currentLayer] = {};
    SceneLayers[currentLayer].id = "scene-" + currentLayer;
    ImageCanvas.append("<div id=\"" + SceneLayers[currentLayer].id +  "\"></div>");
    let element = $("#" + SceneLayers[currentLayer].id)[0];
    SceneLayers[currentLayer].element = element;
    $(element).css("width",width + "px");
    $(element).css("height",height + "px");


    /**
    * DummyValues for Shape to transform
    *
    * @TODO replace it by a creation of 4 edges via UI to perform a quad
    */

    SceneLayers[currentLayer].matrix = {
        tl: {
            x: 180,
            y: 240
        },
        tr: {
            x: 560,
            y: 140
        },
        bl: {
            x: 560,
            y: 310
        },
        br: {
            x: 50,
            y: 370
        },

    };

    let transform = ["", "-webkit-", "-moz-", "-ms-", "-o-"].reduce(function(p, v) { return v + "transform" in document.body.style ? v : p; }) + "transform";

    let transformedArea = createSvgRoot(element,{
        id:"transformedArea" + "-" + currentLayer,
        class: "transformedArea",
        width: width,
        height: height
    });
    let corners = createSvgRoot(element,{
        id:"corners" + "-" + currentLayer,
        class: "corners",
        width: width,
        height: height
    });
    transformedArea.setAttributeNS(null, "transform-origin", "0px 0px 0");
    transformedArea.setAttributeNS(null, "class", "background");

    addCircles(SceneLayers[currentLayer].matrix,corners);
    drawFramesToSceneTransformedArea("#transformedArea-" + currentLayer,width,height);

    updateMatrix3d();
    disableNewSceneButton();
    showaddNewPanelButton();
}

function showaddNewPanelButton() {
    $("#toolbar .btnAddPanel").show();
}

function disableNewSceneButton() {
    $("#toolbar .btnAddScene").hide();
}

function drawFramesToSceneTransformedArea(selector,width,height) {
    var svgTransform = d3.select(selector);
    svgTransform.select("g.background").selectAll(".line--x")
    .data(d3.range(0, width + 1, 40))
    .enter().append("line")
    .attr("class", "line line--x")
    .attr("x1", function(d) { return d; })
    .attr("x2", function(d) { return d; })
    .attr("y1", 0)
    .attr("y2", height);

    svgTransform.select("g.background").selectAll(".line--y")
    .data(d3.range(0, height + 1, 40))
    .enter().append("line")
    .attr("class", "line line--y")
    .attr("x1", 0)
    .attr("x2", width)
    .attr("y1", function(d) { return d; })
    .attr("y2", function(d) { return d; });

}

function updateMatrix3d () {

    let tl = $("#corners-" + currentLayer + " circle:nth-child(1)");
    let tr = $("#corners-" + currentLayer + " circle:nth-child(2)");
    let br = $("#corners-" + currentLayer + " circle:nth-child(3)");
    let bl = $("#corners-" + currentLayer + " circle:nth-child(4)");

    SceneLayers[currentLayer].matrix.tl.x = parseFloat(tl.attr("data-x"));
    SceneLayers[currentLayer].matrix.tl.y = parseFloat(tl.attr("data-y"));
    SceneLayers[currentLayer].matrix.tr.x = parseFloat(tr.attr("data-x"));
    SceneLayers[currentLayer].matrix.tr.y = parseFloat(tr.attr("data-y"));
    SceneLayers[currentLayer].matrix.br.x = parseFloat(br.attr("data-x"));
    SceneLayers[currentLayer].matrix.br.y = parseFloat(br.attr("data-y"));
    SceneLayers[currentLayer].matrix.bl.x = parseFloat(bl.attr("data-x"));
    SceneLayers[currentLayer].matrix.bl.y = parseFloat(bl.attr("data-y"));

    let container = $("#transformedArea-" + currentLayer)[0];
    doTransform(container);
}

function doTransform(targetContainer) {

    let sourcePoints = [[0, 0], [640, 0], [640, 480], [0, 480]];
    let targetPoints = [
    // TL
        [SceneLayers[currentLayer].matrix.tl.x, SceneLayers[currentLayer].matrix.tl.y],
    // TR
        [SceneLayers[currentLayer].matrix.tr.x, SceneLayers[currentLayer].matrix.tr.y],
    // BR
        [SceneLayers[currentLayer].matrix.br.x, SceneLayers[currentLayer].matrix.br.y],
    // BL
        [SceneLayers[currentLayer].matrix.bl.x, SceneLayers[currentLayer].matrix.bl.y]
    ];

    let style = "";
    style += "transform-origin: 0px 0px 0px;";

    for (var a = [], b = [], i = 0, n = sourcePoints.length; i < n; ++i) {
        var s = sourcePoints[i], t = targetPoints[i];
        a.push([s[0], s[1], 1, 0, 0, 0, -s[0] * t[0], -s[1] * t[0]]), b.push(t[0]);
        a.push([0, 0, 0, s[0], s[1], 1, -s[0] * t[1], -s[1] * t[1]]), b.push(t[1]);
    }

    var X = solve(a, b, true), matrix3d = [
        X[0], X[3], 0, X[6],
        X[1], X[4], 0, X[7],
        0,    0, 1,    0,
        X[2], X[5], 0,    1
    ].map(function(x) {
        return x.toPrecision(6);
    });
    style += "transform: matrix3d(" + matrix3d.join(", ") + ")";

    targetContainer.setAttributeNS(null, "style", style);
}

function pasteDataToPictureContainer(event) {
    var data = event.target.result;

    ImageCanvas.append("<img id=\"theImage\">");

    ImageHolder = $('#theImage');
    ImageHolder.attr('src', data);
    var image = new Image();
    image.src = data;

    image.onload = function() {
        // access image size here
        ImageHolder.attr('data-width', this.width);
        ImageHolder.attr('data-height', this.height);
        ImageHolder.attr('data-zoom', 1);

    };
    setImageIsFaulty (false);
}

function setImageIsFaulty (flag) {
    if (flag == true) {
        ImageCanvas.html("");
    }
    if (flag == false) {
        dropArea.hide();
    }
}

function disableCornerDragging () {
    $(SceneLayers[currentLayer].element).find(".corners").hide();
}

function handleAddNewPanel(e){
    stopEventTriggering(e);
    disableCornerDragging();
    let x = 50, y = 50;
    let element = $(SceneLayers[currentLayer].element).find(".transformedArea")[0];
    let panel = appendSVGChild("rect",element,{
        transform: "translate(0,0)",
        class: "panel",
        width: defaultPanel.width,
        height: defaultPanel.height,
        "data-x": x + "",
        "data-y": y + "",
        r: 7,
    });
    $(panel).on('mousedown', handlePanelStartDrag);
}


function addToolBar ( ) {
    $("body").append("<div id=\"toolbar\"/>");
    let toolbar = $("#toolbar");
    toolbar.append("<button class=\"btnAddScene\">+</button>");
    let btnAddScene = $("#toolbar .btnAddScene");
    btnAddScene.addClass("btn btn-primary");
    toolbar.append("<button class=\"btnAddPanel\">#</button>");
    let btnAddPanel = $("#toolbar .btnAddPanel");
    btnAddPanel.addClass("btn btn-primary");
    btnAddPanel.hide();
    btnAddScene.click(handleAddNewShape3d);
    btnAddPanel.click(handleAddNewPanel);
}

function addCircles (collection,container) {
    for (let name in collection) addCircle(collection[name].x,collection[name].y,container)
}

function handleCornerStartDrag(e) {
    stopEventTriggering(e);
    let target = e.currentTarget;
    if ($(target).hasClass("handle")) selectedCorner = target;
    $("body").on('mousemove', handleCornerDrag);
    $(target).on('mouseup', handleCornerEndDrag);
//    $(target).on('mouseleave', handleCornerEndDrag);
    $(target).off('mousedown', handleCornerStartDrag);
}
function handlePanelStartDrag(e) {
    stopEventTriggering(e);
    let target = e.currentTarget;
    if ($(target).hasClass("panel")) selectedPanel = target;
    $("body").on('mousemove', handlePanelDrag);
    $(target).on('mouseup', handlePanelEndDrag);
//    $(target).on('mouseleave', handlePanelEndDrag);
    $(target).off('mousedown', handlePanelStartDrag);
}

function handlePanelDrag(e) {
    stopEventTriggering(e);
    let target = selectedPanel;
    let dragX = e.clientX;
    let dragY = e.clientY;
    updatePanelXY(target,dragX,dragY);
}


function handleCornerDrag(e) {
    stopEventTriggering(e);
    let target = selectedCorner;
    let dragX = e.clientX;
    let dragY = e.clientY;
    updateCornerXY(target,dragX,dragY);
    updateMatrix3d();
}

function handleCornerEndDrag(e) {
    stopEventTriggering(e);
    let target = e.currentTarget;
    selectedCorner = null;
    $("body").off('mousemove', handleCornerDrag);
    $(target).off('mouseup', handleCornerEndDrag);
//    $(target).off('mouseleave', handleCornerEndDrag);
    $(target).on('mousedown', handleCornerStartDrag);
    updateMatrix3d();
}


function handlePanelEndDrag(e) {
    stopEventTriggering(e);
    let target = e.currentTarget;
    selectedPanel = null;
    $("body").off('mousemove', handlePanelDrag);
    $(target).off('mouseup', handlePanelEndDrag);
//    $(target).off('mouseleave', handlePanelEndDrag);
    $(target).on('mousedown', handlePanelStartDrag);
}

function updateCornerXY(target,x,y){
    target.setAttributeNS(null, "transform", "translate(" + x + "," + y + ")");
    target.setAttributeNS(null, "data-x", x + "");
    target.setAttributeNS(null, "data-y", y + "");
}


function updatePanelXY(target,x,y){
    target.setAttributeNS(null, "transform", "translate(" + x + "," + y + ")");
    target.setAttributeNS(null, "data-x", x + "");
    target.setAttributeNS(null, "data-y", y + "");
}


function addCircle(x,y,container) {
    let handle = appendSVGChild("circle",container,{
        transform: "translate(0,0)",
        class: "handle",
        transform: "translate(" + x + "," + y + ")",
        "data-x": x + "",
        "data-y": y + "",
        r: 7,
    });
    $(handle).on('mousedown', handleCornerStartDrag);
}

function initialize () {
    dropArea.on('dragenter', stopEventTriggering);
    dropArea.on('dragover', handleImagDragOver);
    dropArea.on('dragleave', handleImagDragLeave);
    dropArea.on('drop', handleImagDrop);
}
initialize ();